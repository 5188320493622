export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.browser) return false;
  const arr = ["country-about-us", "country-products-slug"];
  let enabledXyz = false;
  const router = useRouter();

  if (arr.includes(router.currentRoute.value.name) && !enabledXyz) {
    import("@animxyz/vue3").then((VueAnimXYZ) => {
      import("@animxyz/core").then((c) => {
        enabledXyz = true;
        nuxtApp.vueApp.use(VueAnimXYZ.default);
      });
    });
  }
  nuxtApp.hook("page:start", () => {
    if (arr.includes(router.currentRoute.value.name) && !enabledXyz) {
      import("@animxyz/vue3").then((VueAnimXYZ) => {
        import("@animxyz/core").then((c) => {
          enabledXyz = true;
          nuxtApp.vueApp.use(VueAnimXYZ.default);
        });
      });
    }
  });
});
